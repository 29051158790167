<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID" prop="userNo" >
        <a-input v-model="form.userNo" placeholder="请输入用户ID" />
      </a-form-model-item>
      <a-form-model-item label="特权卡名称" prop="privilegeTitle" >
        <a-input v-model="form.privilegeTitle" placeholder="请输入特权卡名称" />
      </a-form-model-item>
      <a-form-model-item label="特权卡类型" prop="type" >
        <a-select placeholder="" v-model="form.type" @change="handleChangeType" >
          <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="房间ID" prop="roomIds"  v-if="form.type==2" >
        <a-input v-model="form.roomIds" placeholder="请输入房间ID，多个房间号用户间隔用英文逗号隔开" />
      </a-form-model-item>
      <a-form-model-item label="封面图" prop="coverImg" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.coverImg" :src="form.coverImg" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

<!--      <a-form-model-item label="特权卡数量" prop="num" >-->
<!--        <a-input v-model="form.num" placeholder="请输入特权卡数量" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="当日使用次数限制" prop="useFrequency" >
        <a-input v-model="form.useFrequency" placeholder="请输入当日使用次数限制" />
      </a-form-model-item>
      <a-form-model-item label="到期时间" prop="endDate" >
        <a-date-picker  style="width: 100%"   :disabled-date="disabledStartDate" @change="changeDate" v-model="form.endDate" ></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="特权卡说明" prop="describe" >
        <a-input v-model="form.describe" placeholder="请输入特权卡说明" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPrivilege, addPrivilege, updatePrivilege, getUserPrivilegeList } from '@/api/biz/userPrivilege'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      uploadLoading: false,
      privilegeList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        privilegeId: null,
        privilegeTitle:null,type:null,coverImg:null,describe:null,roomIds:null,
        userId: null,

        num: null,

        useFrequency: null,

        createTime: null,

        remark: null
        ,userNo: null
        ,endDate: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        privilegeTitle: [{ required: true, message: '请输入特权卡名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择特权卡类型', trigger: 'blur' }],
        coverImg: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        privilegeId: [{ required: true, message: '请选择特权卡', trigger: 'blur' }],
        userNo: [{ required: true, message: '请输入用户ID', trigger: 'blur' }],
        useFrequency: [{ required: true, message: '请输入当日使用次数限制', trigger: 'blur' }],
        endDate: [{ required: true, message: '请选择到期时间', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
    this.initCategoryList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleChangeType(val){
       console.log(val);

    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.coverImg = info.file.originFileObj.ossUrl;
      }
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },

    dateAdd(interval,number,date)
    {
      switch(interval)
      {
        case   "y"   :   {
          date.setFullYear(date.getFullYear()+number);
          return   date;
          break;
        }
        case   "q"   :   {
          date.setMonth(date.getMonth()+number*3);
          return   date;
          break;
        }
        case   "m"   :   {
          date.setMonth(date.getMonth()+number);
          return   date;
          break;
        }
        case   "w"   :   {
          date.setDate(date.getDate()+number*7);
          return   date;
          break;
        }
        case   "d"   :   {
          date.setDate(date.getDate()+number);
          return   date;
          break;
        }
        default   :   {
          date.setDate(d.getDate()+number);
          return   date;
          break;
        }
      }
    },
    // 计算最小可选日期
    getMinDate() {
      var now =new Date();
      const minDate =this.dateAdd('y',0,now) ;
      minDate.setDate(minDate.getDate());
      return minDate;
    },
    // 判断开始日期可选范围
    disabledStartDate(date) {
      if (!date) return false;
      const minDate = this.getMinDate();
      return minDate>date;
    },

    changeDate(e){
      let enddate=e.format("YYYY-MM-DD HH:mm:ss")
      console.log(enddate);
      this.form.endDate=enddate;
    },
    //赋值特权卡类型
    changePrivilege(e){
      console.log(e);
      this.privilegeList.forEach(item =>{
        if(item.value == e){
          this.form.type = item.type+"";
        }
      })
    },

    //特权卡下拉数据
    initCategoryList(){
      getUserPrivilegeList(this.queryParam).then(response => {
        for(let i=0;i<response.data.length;i++){
          let params = {
            value:response.data[i].id,
            label:response.data[i].title,
            type:response.data[i].type
          }
          this.privilegeList.push(params);
        }
      });
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        privilegeId: null,
        userId: null,
        num: null,
        useFrequency: null,
        createTime: null, privilegeTitle:null,type:null,coverImg:null,describe:null,roomIds:null,
        remark: null,userNo: null,endDate:null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPrivilege({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePrivilege(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            debugger
            let endDateTemp=this.form.endDate;
            endDateTemp=endDateTemp.substr(0,10);
            this.form.endDate=endDateTemp+" 23:59:59";
            addPrivilege(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

import request from '@/utils/request'


// 查询用户特权卡列表
export function listPrivilege(query) {
  return request({
    url: '/biz/userPrivilege/list',
    method: 'get',
    params: query
  })
}

// 查询用户特权卡分页
export function pagePrivilege(query) {
  return request({
    url: '/biz/userPrivilege/getUserPrivilegePage',
    method: 'get',
    params: query
  })
}

// 查询用户特权卡详细
export function getPrivilege(data) {
  return request({
    url: '/biz/userPrivilege/detail',
    method: 'get',
    params: data
  })
}

// 新增用户特权卡
export function addPrivilege(data) {
  return request({
    url: '/biz/userPrivilege/add',
    method: 'post',
    data: data
  })
}

// 修改用户特权卡
export function updatePrivilege(data) {
  return request({
    url: '/biz/userPrivilege/edit',
    method: 'post',
    data: data
  })
}

// 删除用户特权卡
export function delPrivilege(data) {
  return request({
    url: '/biz/userPrivilege/delete',
    method: 'post',
    data: data
  })
}

export function getUserPrivilegeList(query) {
  return request({
    url: '/biz/privilege/list',
    method: 'get',
    params: query
  })
}
